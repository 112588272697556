
@import url('https://fonts.googleapis.com/css2?family=Dosis:wght@200&family=Montserrat:wght300;400;500;700&family=Roboto:wght@300;700&display=swap');

@font-face {
  font-family: "PosteramaRegular";   /*Can be any text*/
  src: local("PosteramaRegular"),
    url("./fonts/PosteramaRegular.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansLight";   /*Can be any text*/
  src: local("OpenSansLight"),
    url("./fonts/OpenSansLight.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansBold";   /*Can be any text*/
  src: local("OpenSansBold"),
    url("./fonts/OpenSansBold.ttf") format("truetype");
}
@font-face {
  font-family: "OpenSansRegular";   /*Can be any text*/
  src: local("OpenSansRegular"),
    url("./fonts/OpenSansRegular.ttf") format("truetype");
}

.socials:hover {
  filter: invert(11%) sepia(70%) saturate(662%) hue-rotate(77deg)
    brightness(98%) contrast(100%);
  text-decoration: none !important;
}


.show>.dropdown-menu{
  display: block;
  position:absolute;
}

body { 
  background: #595959   !important;
  margin: 0px !important; 
  font-family: 'OpenSansRegular', sans-serif;
  overflow-y: scroll; /* Show vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}

.stepper:hover{
  background-color: #Fa3aa0 !important;
  color: #Fa3aa0 !important;
  cursor: pointer !important;
}

.specialButton:hover{
  border-color: #1afba9 !important;
  opacity: 1 !important;
}
.green {
  color: #1afba9 !important;
}
.pink {
  color: #Fa3aa0 !important;
}

.navbarLink{
  color: white !important;
  text-decoration: none !important;
}
.claimText {
  font-family: 'OpenSansLight', sans-serif;
}

.underlined{
  text-decoration: underline;
}

.border-3 {
    border-width:3px !important;
}

.mainModal { 
  background: #171A21 !important; 
  width: 90%;
}

.image {
  position: relative;
  width: 100%;
  background-image: url(./img/Poly_BG.png);
  background-size: cover;
  background-repeat: no-repeat;
}
.layer {
  background-color: rgba(0, 0, 0, 0.6);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.layerMobile {
  background-color: rgba(0, 0, 0, 0.6);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.inner {
  position:absolute;
  top:60%;
  left:50.5%;
}



.largeText {
  font-size: 400%;
  font-weight: bold;
}

.link {
  color: #FF00FF;
}
.link:hover {
  color: #1AFBA9;
}

.chapter4 {
  position:absolute;
  top:0%;
  left:0%;
}
.copyright {
  font-size: 85%;
  color: #E4E4E4;
}

#card1{
  border-radius:  15px !important;
  background-color: #f8f9fa !important;
  box-shadow: 5px 5px 10px #FF00FF;
  text-decoration-color:  #2d2d2d !important;
  margin-top: 15px  !important; ;
  margin-bottom: 15px  !important; ;
  margin-right: 15px !important; ;
  margin-left: 20px !important;

}

#card2{
  border-radius:  15px !important;
  background-color: #c6c6c6 !important;
  box-shadow: 5px 5px 10px #FF00FF;
  text-decoration-color: #2d2d2d !important;
  margin-top: 15px  !important;
  margin-bottom: 15px  !important;
  margin-right: 15px !important;
  margin-left: 15px !important;
  padding-left:  25px !important;
  padding-right:  25px !important;
  padding-top:  25px !important;
  padding-bottom:  25px !important;

}

#chapter2{
  background-color: #f8f9fa !important;
  padding-top: 40px !important;
  padding-bottom: 50px !important;
  padding-left:  40px !important;
  padding-right: 30px !important;
}

#chapter3{
  background-color: #43of4d !important;
  padding-top: 40px !important;
  padding-bottom: 50px !important;
  padding-left:  60px !important;
  padding-right: 30px !important;
}

#chapter4{
  background-color: #c6c6c6 !important;
  text-color: #2d2d2d !important;
  padding-top:  40px important;
  padding-left:  25px !important;
  padding-right: 30px !important;
  padding-bottom:150px !important;
  margin-bottom: 30px  !important; ;
}
#chapter4inner{
  padding-top: 40px;
  padding-bottom: 30px !important;
  padding-left:  30px !important;
  padding-right: 40% !important;
}

#chapter5{
  background-color: #FFFFFF !important;
  padding-bottom: 50px !important;
  padding-left:  40px !important;
  padding-right: 40px !important;
}

#chapter6{
  padding-bottom: 50px !important;
  padding-left:  30px !important;
  padding-right: 30px !important;
}

#chapter6inner{
  padding-bottom: 20px !important;
  padding-left:  30px !important;
  padding-right: 30% !important;
}

#mainBox{
  background-color: #424242;
  width: 100% !important;
  margin: 0 !important;
  margin: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  border-style: solid;
  border-width: 0px;
  border-color: white !important;
  width: 100vw !important;

  overflow-y: scroll; /* Show vertical scrollbar */
  overflow-x: hidden; /* Hide horizontal scrollbar */
}